// import styled from 'styled-components'
import React from "react";
import DeveloperAPI from "../services/DeveloperAPIClient";
import { navigate } from "gatsby";
import Layout from "../components/layout/auth";
import { ErrorMessage, Form, Formik } from "formik";
import { FormErrorContainer } from "../components/forms";
import { Link } from "@reach/router";
import { Button, FormGroup, InputGroup } from "@blueprintjs/core";

// const ForgotPasswordContainer = styled.p`
//   margin-top: 0.1rem;
//   margin-bottom: 0.6rem;
//   font-size: 1rem;
//   color: #3686a5;
//   text-align: right;
// `
const ForgotPasswordContainer = props => <p {...props} />;

class Login extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   redirectToReferrer: false,
    //   email: undefined,
    //   password: undefined,
    //   loggingIn: false,
    //   loginError: undefined
    // }
    this.login = this.login.bind(this);
    this.state = {
      redirectToReferrer: false
    };
  }

  async login(values, actions) {
    try {
      await DeveloperAPI.login(values.email, values.password);
      this.setState({
        loginError: undefined,
        redirectToReferrer: true
      });
    } catch (error) {
      // Only set this false when we there is an error because otherwise, React complains about
      // render depending on things other than props and state.
      actions.setSubmitting(false);
      if (error.networkError) {
        this.setState({ loginError: error.message });
      } else if (error.server) {
        this.setState({ loginError: "Server error. Please try again later." });
      } else if (error.client) {
        const clientError = error.data;
        if (clientError.errors && clientError.errors.email) {
          actions.setFieldError("email", clientError.errors.email[0]);
        } else {
          this.setState({ loginError: "Unknown client login error" });
        }
      } else {
        this.setState({ loginError: "Unknown error" });
      }
    }
  }

  validate(values) {
    let errors = {};
    if (!values.email || values.email.trim() === "") {
      errors.email = "Email is a required field";
    }
    if (!values.password || values.password.trim() === "") {
      errors.password = "Password is a required field";
    }
    return errors;
  }

  render() {
    /**
     * Public routes need to be in both the router and in stand alone files. Otherwise, what
     * happens is that the standalone pages won't be accessible from the router or by directly
     * going to the page
     * -- Vinay 11/20/2018
     */
    // const {from} = this.props.location.state || {from: {pathname: "/"}};
    const { redirectToReferrer } = this.state;
    if (redirectToReferrer) {
      navigate("/", { replace: true });
      return null;
    }

    return (
      <Layout
        title="Login"
        footer={
          <Link to={"/signup/"} style={{ marginTop: 40, color: "#AAA" }}>
            Don't have an account? Sign up here.
          </Link>
        }
      >
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={this.validate}
          onSubmit={this.login}
        >
          {({ isSubmitting, errors, values, handleChange }) => (
            <Form style={{ width: 300 }}>
              <FormGroup label={"Email"} labelFor={"email"}>
                <InputGroup
                  placeholder={"Email"}
                  large
                  id={"email"}
                  type={"email"}
                  autoComplete={"email"}
                  name={"email"}
                  value={values.email}
                  onChange={handleChange}
                />
              </FormGroup>
              <ErrorMessage name="email" component={FormErrorContainer} />

              <FormGroup label={"Password"} labelFor={"password"}>
                <InputGroup
                  placeholder={"Password"}
                  large
                  type={"password"}
                  autoComplete={"password"}
                  id={"password"}
                  name={"password"}
                  value={values.password}
                  onChange={handleChange}
                />
              </FormGroup>
              <ErrorMessage name="password" component={FormErrorContainer} />
              <ForgotPasswordContainer>
                <Link to="/forgot_password/">Forgot your password?</Link>
              </ForgotPasswordContainer>
              <Button type="submit" disabled={isSubmitting} large fill>
                Log in
              </Button>
            </Form>
          )}
        </Formik>
      </Layout>
    );
  }
}

export default Login;
